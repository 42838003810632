import React, { Component } from 'react';

import logo from './logo.svg';
import './App.scss';
import {fetcher} from "./utils/fetcher";
import { wikiPage } from "./constants";
import  { privacyPolicy } from "./copy/privacy-policy";

import { Header } from './components/shared/Header/Header';
import $ from 'jquery';

class App extends Component {

  state = {
    title: '',
    content: '',
    todayCSSel: '',
    jsxContent: '',
  };

  niceLog(v) {
    const keys = Object.keys(v);
    const key = keys[0];
    //const values = Object.values(v[keys]);
    console.log(key, " = ", v[keys])
  }

  scanAtags() {

    $('a').each(function() {
      const href = $(this).attr('href')//.css('background-color', 'yellow');
      if ( href  && href.includes('/w/index.php?title') ) {
        $(this).replaceWith($(this).text());
      }

    });

    var elements = $("div").filter(function() {
      // only keep the elements that have the correct color, replace `#000` with the color you care about
      if ( $(this).css("backgroundColor") === "rgb(230, 230, 250)" ) {
        $(this).css("backgroundColor", '#e2e2e2')
      };

      return $(this).css("backgroundColor") === "rgb(230, 230, 250)";
    });

  }

  removeEditButtons() {
    $('.mw-editsection').remove();
  }

  /**
   * @description gets full Wikipedia article
   * @returns {Promise<*>}
   */
  async getFullWikiPage() {

    if ( window.location.pathname === '/privacy-policy')
    {
      this.setState({title: 'Privacy Policy', jsxContent: privacyPolicy})
    }
    else {

      const path = window.location.pathname.split("/wiki/")[1];

      //let url = wikiPage;
      let payload = { page : wikiPage};

      console.log('path = ', path);
      //pages other than the Homepage
      if (path) {
        //url = wikiPage.replace('Deaths_in_2020', path);
        payload.page = path;
      }
      console.log('payload = ', payload);
      const
          url = 'https://tzdunapws9.execute-api.us-east-1.amazonaws.com/dev/getContent',
          fullWikiPageData = await fetcher(url, 'GET', payload);

      console.log('fullWikiPageData = ', fullWikiPageData);
      const
          { parsedResp } = fullWikiPageData.data,
          title = fullWikiPageData.data && fullWikiPageData.data.parse ?
              fullWikiPageData.data.parse.displayTitle
              :
              fullWikiPageData.data.error.info;

      const content = fullWikiPageData.data.parse ?
          fullWikiPageData.data.parse.text["*"]
          :
          "";

      console.log('title ', title);

      this.setState({title, content});

      this.scanAtags();
      this.removeEditButtons();
    }

  }

  componentDidMount() {
    this.getFullWikiPage();

    // const getAndPost = fetcher('https://tzdunapws9.execute-api.us-east-1.amazonaws.com/dev/getAndPost')
    // console.log('getAndPost ', getAndPost)
  }

  title() {
    return (
        <h1> {this.state.title} </h1>
    )
  }

  htmlContent() {
    return (
        <div dangerouslySetInnerHTML={ {__html: this.state.content}}/>
    )
  }

  jsxContent() {
    return (
        <div> {this.state.jsxContent} </div>
    )
  }

  render() {
    console.log('this.state ', this.state);
    return (
        <div className="App">
          <Header/>
            <div className="body">
              { this.title() }
              { this.htmlContent() }
              { this.jsxContent() }
            </div>
        </div>
    );
  }

}

export default App;
