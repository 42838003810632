// FB Urls and params
export const fbGraphUrl = 'https://graph.facebook.com/';
const fbApiVersion = 'v4.0/';
export const fb4dot0GraphUrl = fbGraphUrl + fbApiVersion;
export const fbAppId = '&client_id=435938093716703';
export const fbAppSecret = '&client_secret=3f3460416b324b54e76d0364d9f3e952&';
export const fbGrantType = 'grant_type=fb_exchange_token&';

// Reflecting News Page ID : 110963873723189
// Wikipedia Alerts Page ID : 100444921374588
export const fbPageId = '110963873723189';

// created on 0ctober 15, 2019
// const user_acces_token = JSON.parse(localStorage.getItem('fbAccess')).token;
// export const fbExchangeToken = 'fb_exchange_token=' + user_acces_token;
// export const fbLongTermAccessToken='access_token='+ user_acces_token;

/**
 * FB API Endpoints
 */

//extend token API, which worked giving it both a short OR long term Token
    // documentation: https://developers.facebook.com/docs/facebook-login/access-tokens/refreshing
//export const fbExtendToken =  fb4dot0GraphUrl + 'oauth/access_token?' + fbGrantType + fbAppId + fbAppSecret + fbExchangeToken;

//post a new entry to FB Page
//export const fbPostMessageToPageUrl = fb4dot0GraphUrl + fbPageId + '/feed?message=$message$&link=$url$&'+ fbLongTermAccessToken;

//get all Posts on the FB page
//export const fbGetPagePosts = fb4dot0GraphUrl + fbPageId + '/feed?' + fbLongTermAccessToken;


// Wiki Urls and params
export const wikiBaseUrl = 'https://en.wikipedia.org/w/api.php?';
export const wikiPage = 'Deaths_in_2020';
const wikiFullPageParams = 'action=parse&page='+wikiPage+'&limit=1&format=json&origin=*';
export const wikiFullDeathPage = wikiBaseUrl + wikiFullPageParams;

// gets revisions for Deaths in 2019 article per timestamp for the last 25 hours
// 25 hours = 1500
//rvdir - In which direction to enumerate:
//      newer - List oldest first. Note: rvstart has to be before rvend.
//      older -List newest first (default). Note: rvstart has to be later than rvend.
const
    params = 'action=query&format=json&prop=revisions&titles='+wikiPage+'&',
    rvprops = 'rvprop=timestamp|user|comment|ids|content|slotsize|size|userid|sha1|slotsha1|contentmodel|parsedcomment|tags|roles&',
    rvslots ='rvslots=*&',
    utcStart = encodeURIComponent(new Date().toISOString()).toString(),
    hours = 25,
    utcEnd = new Date( new Date().getTime() - (60000 * (hours * 60)) ).toISOString(),
    rvend = 'rvend='+utcEnd+'&',
    rvstart = 'rvstart='+utcStart+'&',
    rvdir = 'rvdir=older&',
    rvlimit ='rvlimit='+ 50+'&';

export const wikiRevisionApi = wikiBaseUrl + params + rvstart + rvend + rvdir + rvprops + rvlimit + 'origin=*';
