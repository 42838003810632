import React from 'react';
export const privacyPolicy = (
    <div>
            <h3> The only data we currently (as of December 2019) collect is through Google Analytics.  This is subject to change, and any changes will be reflected here  </h3>
            <h3>Information We *MAY* Collect About You and How We Collect It</h3>

            <p>
                    We collect several types of information from and about users of our Services, including information:

                    By which you may be personally identified, such as name, postal address, e-mail address, telephone number, IP address, or any other identifier by which you may be contacted online or offline ("personal information");
                    That is about you but individually does not identify you; and/or
                    About your internet connection, the equipment you use to access our Website, and usage details.

            </p>
            <h4>We collect this information:</h4>
            <p>
                    Directly from you when you provide it to us.
                    Automatically as you use through the Services. Information collected automatically may include usage details, IP addresses, and information collected through cookies, web beacons, and other tracking technologies.
                    From third parties, for example, our business partners.
                    Information You Provide to Us

                    The information we collect on or through our Services may include:

                    Information that you provide by filling in forms on our Website. This includes information provided at the time of registering to use our Website, subscribing to our service, posting material, or requesting further services. We may also ask you for information when you enter a contest or promotion sponsored by us, and when you report a problem with our Website.
                    Records and copies of your correspondence (including email addresses), if you contact us.
                    Your responses to surveys that we might ask you to complete for research purposes.
                    Details of transactions you carry out through our Website and of the fulfillment of your orders.

                    Information from Other Sources

                    As you navigate through and interact with our Services, we may use automatic data collection technologies to collect certain information about your equipment, browsing actions, and patterns, including:

                    Details of your visits to our Website, including traffic data, location data, logs, and other communication data and the resources that you access and use on the Website.
                    Information about your computer and internet connection, including your IP address, operating system, and browser type
                    Automatically collected information such as your mobile carrier, mobile advertising identifiers, MAC address, IMEI, Advertiser ID, and other device identifiers that are automatically assigned to your computer or device when you access the Internet, geo-location information, hardware type, operating system, Internet service provider, pages that you visit before and after using the Services, and other actions taken through use of the Services such as user preferences.
                    We also may use these technologies to collect information about your online activities over time and across third-party websites or other online services (behavioral tracking).

                    The information we collect automatically may include personal information, or we may maintain it or associate it with personal information we collect in other ways or receive from third parties. It helps us to improve our Services and to deliver a better and more personalized service, including by enabling us to:

                    Estimate our audience size and usage patterns.
                    Store information about your preferences, allowing us to customize our Website according to your individual interests.
                    Recognize you when you return to our Website.
                    Customize and improve our Service offerings.
                    The technologies we use for this automatic data collection may include:

                    Cookies. A cookie is a small file placed on the hard drive of your computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser. However, if you select this setting you may be unable to access certain parts of our Website. Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies when you direct your browser to our Website.
                    Flash Cookies. Certain features of our Website may use local stored objects (or Flash cookies) to collect and store information about your preferences and navigation to, from, and on our Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For information about managing your privacy and security settings for Flash cookies, see Choices About How We Use and Disclose Your Information.
                    Web Beacons. Pages of our Website and our emails may contain small electronic files known as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company, for example, to count users who have visited those pages or opened an email and for other related website statistics (for example, recording the popularity of certain website content and verifying system and server integrity).
                    Social Media Services. You can interact with your accounts on other social media services, in connection with your use of the Services or interaction with us. By using these tools, you acknowledge that some account information may be transmitted from the applicable social media service account to us; our treatment of that information is covered by this Privacy Policy. These social media services may be collecting information about your online activity through its own tracking technologies, subject to their own privacy policies.  Please refer to these policies to learn more about how social media services collect and use information.
                    Third-Party Use of Cookies and Other Tracking Technologies

                    Some content or applications, including advertisements, on the Website are served by third-parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies alone or in conjunction with web beacons or other tracking technologies to collect information about you when you use our website. The information they collect may be associated with your personal information or they may collect information, including personal information, about your online activities over time and across different websites and other online services. They may use this information to provide you with interest-based (behavioral) advertising or other targeted content.

                    We do not control these third parties' tracking technologies or how they may be used. If you have any questions about an advertisement or other targeted content, you should contact the responsible provider directly. For information about how you can opt out of receiving targeted advertising from many providers, see Choices About How We Use and Disclose Your Information.

            </p>
            <h3>How We Use Your Information </h3>
            <p>
                    We use information that we collect about you or that you provide to us, including any personal information:

                    To present our Website and its contents to you.
                    To provide you with information, products, or services.
                    To fulfill any other purpose for which you provide it.
                    To communicate with you about your account, including expiration and renewal notices, and providing you with account management services.
                    To notify you about changes to our Website or any products or services we offer or provide though it.
                    To allow you to participate in interactive features on our Website.
                    In any other way we may describe when you provide the information.
                    For any other purpose with your consent.
                    We may also use your information to contact you about goods and services that may be of interest to you. If you do not want us to use your information in this way, please adjust your user preferences in your account profile. For more information, see Choices About How We Use and Disclose Your Information.

                    We may use the information we have collected from you to enable us to display advertisements to our advertisers' target audiences. Even though we do not disclose your personal information for these purposes without your consent, if you click on or otherwise interact with an advertisement, the advertiser may assume that you meet its target criteria.

                    Disclosure of Your Information

                    We may disclose aggregated information about our users, and information that does not identify any individual, without restriction.

                    We may disclose personal information that we collect or you provide as described in this privacy policy:

                    To our subsidiaries and affiliates.
                    To contractors, service providers, and other third parties we use to support our business.
                    To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of Reflecting News, Inc.'s assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by Reflecting News, Inc. about our Website users is among the assets transferred.
                    To fulfill the purpose for which you provide it.
                    For any other purpose disclosed by us when you provide the information.
                    With your consent.
                    For Feedback and User Testimonials. Users may choose to write testimonials, reviews, and comments through the Website and Services or social media services.  Such content is publicly available, though we reserve the right to not publish any content submitted, or modify all content to meet our editorial standards. When we publish this content, we may identify our users by their first name, last initial and locality. Any data that users submit you submit in testimonials can be read, collected, or used by other users.  We are not responsible for the any personal information you choose to submit in reviews, testimonials, or feedback, or third parties’ use of such information.
            </p>
            <h3>
                    We may also disclose your personal information:
            </h3>
            <p>

                    To comply with any court order, law, or legal process, including to respond to any government or regulatory request.
                    To enforce or apply our Terms of Use and other agreements
                    If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of Reflecting News, Inc., our customers, or others. This includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction.
                    Choices About How We Use and Disclose Your Information

                    We strive to provide you with choices regarding the personal information you provide to us. We have created mechanisms to provide you with the following control over your information:

                    Tracking Technologies and Advertising. You can set your browser to refuse all or some browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, please note that some parts of this site may then be inaccessible or not function properly.
                    We do not control third parties' collection or use of your information to serve interest-based advertising. However these third parties may provide you with ways to choose not to have your information collected or used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative ("NAI") on the NAI's website.

                    Data Security

                    We have implemented measures designed to secure your personal information from accidental loss and from unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our secure servers behind firewalls.

                    The safety and security of your information also depends on you. Where we have given you (or where you have chosen) a password for access to certain parts of our Website and mobile application, you are responsible for keeping this password confidential. We ask you not to share your password with anyone.

                    Unfortunately, the transmission of information via the internet is not completely secure. Although we do our best to protect your personal information, we cannot guarantee the security of your personal information transmitted to our Website or other Services. Any transmission of personal information is at your own risk. We are not responsible for circumvention of any privacy settings or security measures contained on our Services.

                    Changes to Our Privacy Policy

                    It is our policy to post any changes we make to our privacy policy on this page. If we make material changes to how we treat our users' personal information, we will notify you on this website. The date the privacy policy was last revised is identified at the top of the page. You are responsible for ensuring we have an up-to-date active and deliverable email address for you, and for periodically visiting our Website and this privacy policy to check for any changes. If you use the Services after changes to the Policy have been posted, you are agreeing to all of those changes.

                    Extraterritorial Users

                    This Policy is intended to cover collection of information from residents of the United States and Canada (the “Territory”). If you are accessing the Services from outside the Territory, please be aware that your information may be transferred to, stored, and processed in the United States where our servers are located. The data protection and other laws of the United States might not be as comprehensive as those in your country.

            </p>
            <h3>
                    Notice To EEA Residents
            </h3>

                 <p>   The Services are not available in the European Economic Area (the “EEA”). If you are an EEA resident, the only way we will receive personal data about you is if you choose to contact us and provide information (such as your email address) that will allow us to respond. By doing so, you explicitly consent to our storing such personal data in the United States and subject to this Policy.
            </p>

    </div>
);
