import React from 'react';
import './Header.scss';

import logo from '../../assets/icons/Public_Alerts_50.jpeg';
import Wlogo from '../../assets/icons/75px_Wikipedia_W.svg.png';

export const Header = () => {

    return(
        <div className='container'>

            <div className='title-container'>
                {/*<div style={{ display: 'flex', alignItems: 'center'}}>*/}
                    {/*/!*<div className='Wlogo'>*!/*/}
                        {/*/!*<img src={Wlogo}/>*!/*/}
                    {/*/!*</div>*!/*/}
                    {/*<h1><span style={{color: 'red'}}>R</span>EFLECTING</h1>*/}
                {/*</div>*/}
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    {/*<div className='logo'>*/}
                        {/*<img src={logo}/>*/}
                    {/*</div>*/}
                    <a href="/" style={{textDecoration: 'none'}}>
                    <h1><span style={{color: '#0059b3'}}>R</span>EFLECTING <span style={{}}>N</span>EWS</h1>
                    </a>
                </div>
            </div>

        </div>
        )

};


