
const stringIt = payload => {
    return JSON.stringify( payload )
};


const fetchOptions = {
    GET: { method: 'GET' },
    POST: { method: 'POST'},
    PUT: { method: 'PUT' },
    DELETE: { method: 'DELETE' },
    PATCH: { method: 'PATCH'}
};

export const fetcher = async ( url, httpVerb, payload ) => {

    let endpoint = url;

    if( !httpVerb ){
        httpVerb = 'GET'
    }

    if (httpVerb === 'GET' && payload && Object.keys(payload).length){
        endpoint = endpoint +'?'+ Object.keys(payload)+'='+Object.values(payload);
    }

    if( payload && httpVerb !== 'GET'){
        fetchOptions[httpVerb]['body'] = stringIt( payload );
    }

    //console.log( url," fetchOptions = ", fetchOptions[httpVerb], " and payload = ", payload );
    //console.trace("API call")

    return fetch( endpoint, fetchOptions[httpVerb] )
        .then( res => {
            return res.json()
        })
        .catch( err => {
            console.log("err = ", err)
            return err })
        .then( parsedRes => {
            if( !parsedRes ){
                return
            }

            return parsedRes;

        });

};
